import authAxios from "./config/need-auth-axios";
import bitrahAxios from "./config/bitrah-axios";

class currenciesService {
    getCurrencies() {
        return bitrahAxios.get("/manifest/board/currencies");
    }
    getBitrahCurrencies(merchantID) {
        return bitrahAxios.get("/manifest/board/coins" + (merchantID !== undefined? ("/" + merchantID + "/rd") : '/rd'));
    }
    calculateValue(rialValue,iso){
        return authAxios.post("/manifest/order/calculate",{
            rialValue: rialValue,
            coin:iso
        });
    }
    guestCalculateValue(rialValue,iso){
        return bitrahAxios.post("/guest/calculate",{
            rialValue: rialValue,
            coin:iso
        });
    }
}

export default new currenciesService();
