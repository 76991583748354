import { mapActions, mapGetters } from "vuex";
import * as types from "../../../store/types";
import userInfoService from "../../../services/userInfoService";
import currenciesService from "../../../services/currenciesService";
import { ASSETS_URL } from "../../../services/base";
import paymentService from "../../../services/paymentService";
import $formatNumber from "../../../mixins/formatNumber";
import $preventToEnterNegativeNumber from "../../../mixins/preventToEnterNegativeNumber";
import Num2persian from "num2persian";
import userGateWayStatusService from "../../../services/userGateWayStatusService";
import { VMoney } from "v-money";
// import input                      from "@/components/input/input";
import { MAX_AMOUNT } from "@/utlities/currenciesminMaxAmount";
import $minValidation from "@/mixins/MinValueValidation"

export default {
  name: "customer",
  components: {},
  props: [],
  mixins: [$formatNumber, $preventToEnterNegativeNumber, $minValidation],
  directives: { money: VMoney },
  data() {
    return {
      baseUrl: ASSETS_URL,
      priceValue: "",
      convertedIcon: "",
      convertedTitle: "",
      convertedISO: "",
      money: {
        thousands: ",",
        precision: false,
        masked: false /* doesn't work with directive */
      }
    };
  },
  computed: {
    ...mapGetters({
      userInfo: types.GET_USER_INFO,
      currencies: types.GET_CURRENCIES,
      bitrahCurrencies: types.GET_BITRAH_CURRENCIES,
      userGateway: types.GET_USER_GATEWAY_STATUS
    }),
    toPersian() {
      if (this.priceValue) {
        this.priceValue = `${this.priceValue}`;
        let toValue = this.priceValue.replace(/,/g, "");
        parseFloat(toValue);
        return Num2persian((toValue / 10).toFixed(0));
      } else {
        return 0;
      }
    }
  },
  mounted() {
    this.getInfoData();
    this.getcurrencies();
    this.getBitrahCurrenciesFunc();
    setTimeout(() => {
      this.getUserGatewayStatusData();
    }, 1300);
  },
  methods: {
    ...mapActions({
      userInfoAction: types.ACTION_USER_INFO,
      currenciesAction: types.ACTION_CURRENCIES,
      bitrahCurrenciesAction: types.ACTION_BITRAH_CURRENCIES,
      userGateWayAction: types.ACTION_USER_GATEWAY_STATUS
    }),
    getUserGatewayStatusData() {
      userGateWayStatusService
        .getUserGateWay(this.userInfo.username, this.IPAddress)
        .then(response => {
          this.userGateWayAction(response.data);
        })
        .catch(response => {
          this.$bvToast.toast(response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            solid: false
          });
        })
        .finally(() => (this.loading = false));
    },
    getBitrahCurrenciesFunc() {
      currenciesService
        .getBitrahCurrencies()
        .then(response => {
          this.bitrahCurrenciesAction(response.data);
          this.convertedIcon = response.data[0].logo;
          this.convertedTitle = response.data[0].name;
          this.convertedISO = response.data[0].iso;
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        });
    },
    // DUE TO SOME ISSUES WITH THIS METHOD I HAVE ADDED THE V-MONEY DIRECTIVE
    // formatNumberFunc() {
    //     if (
    //         this.priceValue === 0 ||
    //         this.priceValue === "0" ||
    //         this.priceValue === ""
    //     ) {
    //         this.priceValue = '0';
    //     }
    //     this.priceValue = this.$formatNumber(
    //         parseFloat(this.priceValue.replace(/,/g, ""))
    //     );
    // },
    buttonsInput(number) {
      this.priceValue += number;
      if (
        this.priceValue === 0 ||
        this.priceValue === "0" ||
        this.priceValue === ""
      ) {
        this.priceValue = "0";
      }
      this.priceValue = this.$formatNumber(
        parseFloat(this.priceValue.replace(/,/g, ""))
      );
    },
    clearInput() {
      this.priceValue = "";
    },
    clearACharacterInput() {
      this.priceValue = `${this.priceValue}`.replace(/,/g, "");
      this.priceValue = this.priceValue.slice(0, this.priceValue.length - 1);
      if (
        this.priceValue === 0 ||
        this.priceValue === "0" ||
        this.priceValue === ""
      ) {
        this.priceValue = 0;
      } else {
        this.priceValue = this.$formatNumber(parseFloat(this.priceValue));
      }
    },
    changeCurrency(currency) {
      this.convertedIcon = currency.logo;
      this.convertedTitle = currency.name;
      this.convertedISO = currency.iso;
    },
    getInfoData() {
      userInfoService
        .getUserInfo()
        .then(response => {
          this.userInfoAction(response.data);
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        })
        .finally(() => (this.loading = false));
    },
    getcurrencies() {
      currenciesService
        .getCurrencies()
        .then(response => {
          this.currenciesAction(response.data);
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        })
        .finally(() => (this.loading = false));
    },
    addAmountToUrlQuery(evt) {
      evt.preventDefault();
      if (!this.checkNUmber(this.priceValue) || this.priceValue == 0) {
        this.$bvToast.toast(this.$i18n.t("panel.merchant.pay.checkZeroValue"), {
          title: this.$i18n.t("toast.errorTitle"),
          noCloseButton: true,
          variant: "custom",
          bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
          headerClass: "dangerBackgroundColor dangerFontColor  rtl text-right",
          solid: false
        });
      } else if (
        this.validateMinValue(
          this.priceValue,
          this.convertedISO
        )
      ) {
        // validateMinValue is defined as a mixin
        let minAmountMsg = this.validateMinValue(
          this.priceValue,
          this.convertedISO
        );
        this.$bvToast.toast(
          this.$i18n.t(`panel.merchant.pay.${minAmountMsg}`),
          {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          }
        );
      } else if (this.priceValue > MAX_AMOUNT) {
        this.$bvToast.toast(this.$i18n.t("panel.merchant.pay.checkMaxAmount"), {
          title: this.$i18n.t("toast.errorTitle"),
          noCloseButton: true,
          variant: "custom",
          bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
          headerClass: "dangerBackgroundColor dangerFontColor  rtl text-right",
          solid: false
        });
      } else {
        paymentService
          .ATMPayment(
            this.priceValue.replace(/,/g, ""),
            this.convertedISO,
            localStorage.getItem("bitrahAccessToken")
          )
          .then(response => {
            let id = this.$route.params.id;
            let merchantId = this.$route.query.merchantId;
            this.$router.replace({
              path: "/" + `${this.$i18n.locale}` + "/" + id,
              query: {
                token: response.data,
                merchantId: merchantId,
                mode: "off",
                coin: this.convertedISO,
                amount: this.priceValue
              }
            });
          })
          .catch(response => {
            this.$bvToast.toast(response.data.message, {
              title: this.$i18n.t("toast.errorTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
              headerClass:
                "dangerBackgroundColor dangerFontColor  rtl text-right",
              solid: false
            });
          });
      }
    },
    checkNUmber(inputtxt) {
      // TYPE CASTING BEFORE USING THE REPLACE METHOD (due to an error with clearing the number in calculator)
      inputtxt = String(inputtxt);
      // eslint-disable-next-line no-useless-escape
      inputtxt = inputtxt.replace(/\,/g, "");
      if (inputtxt && inputtxt !== 0) {
        this.priceValue = `${Number(
          inputtxt
            .replace(/[٠١٢٣٤٥٦٧٨٩]/g, function(d) {
              return d.charCodeAt(0) - 1632; // Convert Arabic numbers
            })
            .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function(d) {
              return d.charCodeAt(0) - 1776; // Convert Persian numbers
            })
        )}`;
        let numbers = /^[0-9]+$/;
        return (
          !!this.priceValue.match(numbers) &&
          (this.priceValue !== "0" || this.priceValue !== 0)
        );
      } else {
        return false;
      }
    }
  }
};
